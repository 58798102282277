import img1 from './components/images/1.jpg'
import img2 from './components/images/2.jpg'
import img3 from './components/images/3.jpg'
import img4 from './components/images/4.jpg'
import img5 from './components/images/5.jpg'
import img6 from './components/images/6.jpg'
import img7 from './components/images/7.jpg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={img1} alt="img1" />
        <img src={img2} alt="img2" />
        <img src={img3} alt="img3" />
        <img src={img4} alt="img4" />
        <img src={img5} alt="img5" />
        <img src={img6} alt="img6" />
        <img src={img7} alt="img7" />
        <p></p>
      </header>
    </div>
  );
}

export default App;
